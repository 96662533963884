@import url('https://fonts.googleapis.com/css?family=Roboto');
html {
    scroll-behavior: smooth;
  }

@keyframes comeIn {
    0% { transform: scale(0) }
    75% { transform: scale(1.03) }
    100% { transform: scale(1) }
}

.xmasonry .xblock {
    animation: comeIn ease 0.5s;
    animation-iteration-count: 1;
    transition: left .3s ease, top .3s ease;
}

.card-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* or the number of lines you want */
    word-break: break-word; /* optional, if you need word breaks */
    text-align: center;
  }
.card-message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 10; /* or the number of lines you want */
    word-break: break-word; /* optional, if you need word breaks */
  }

